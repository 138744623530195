import * as React from "react";
import { Button } from "react-bootstrap";
import * as Yup from "yup";

import Table from '../../components/EditableTable';
import { request } from '../../sagas/index';

const NotesTable = ({ columns, tenantID, onUpdate }: any) => {
  const [selectedColumn, setSelectedColumn] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const formSubmit = async ({ notes, noteID }: any) => {
    await request.put(`/notes/put`, {
      t: tenantID,
      notes,
      noteID,
    });

    onUpdate({ notes, noteID }, selectedIndex);

    setSelectedColumn(null);
    setSelectedIndex(null);
  };

  return (
    <Table
      headers={ [
        {
          label: '',
          component: (selectedColumn: any, selectedIndex: any) => <Button
            variant='link'
            onClick={ () => {
              setSelectedColumn(selectedColumn);
              setSelectedIndex(selectedIndex);
            } }>
              Edit
            </Button>,
          editStateComponent: (value: any, selectedIndex: any, { handleSubmit }: any) => <Button variant='link' onClick={ handleSubmit }>Save</Button>,
        },
        {
          label: 'Notes',
          placeholder: 'notes',
          field: 'notes',
          type: 'text',
        },
        {
          label: 'Date',
          field: 'createdDate',
          type: 'hidden',
        },
        {
          label: '',
          editStateComponent: () => {
            return (
              <Button variant='link' onClick={ () => {
                setSelectedColumn(null);
                setSelectedIndex(null);
              } }>Cancel</Button>
            );
          }
        },
      ] }
      columns={ columns }
      selectedIndex={ selectedIndex }
      selectedColumn={ selectedColumn }
      formSubmit={ formSubmit }
      validationSchema={ Yup.object().shape({
        notes: Yup.string().required('notes is required'),
      }) }
    />
  );
}

export default NotesTable;
