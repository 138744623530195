export const GET_SCHEDULER_TECHNICIANS = 'GET_SCHEDULER_TECHNICIANS';
export const SET_SCHEDULER_TECHNICIANS  = 'SET_SCHEDULER_TECHNICIANS';

export const GET_SCHEDULER_PROJECTS = 'GET_SCHEDULER_PROJECTS';
export const SET_SCHEDULER_PROJECTS  = 'SET_SCHEDULER_PROJECTS';
export const UPDATE_SCHEDULER_PROJECT  = 'UPDATE_SCHEDULER_PROJECT';
export const ADD_SCHEDULER_PROJECT  = 'ADD_SCHEDULER_PROJECT';

export const GET_SCHEDULER_UNASSIGNED_PROJECTS  = 'GET_SCHEDULER_UNASSIGNED_PROJECTS';
export const SET_SCHEDULER_UNASSIGNED_PROJECTS  = 'SET_SCHEDULER_UNASSIGNED_PROJECTS';

export const GET_SCHEDULER_FIELD_VALUES  = 'GET_SCHEDULER_FIELD_VALUES';
export const SET_SCHEDULER_FIELD_VALUES  = 'SET_SCHEDULER_FIELD_VALUES';

export const UPDATE_SCHEDULER_FIELD_VALUES  = 'UPDATE_SCHEDULER_FIELD_VALUES';

export const GET_SCHEDULER_VEHICLE  = 'GET_SCHEDULER_VEHICLE';
export const SET_SCHEDULER_VEHICLE  = 'SET_SCHEDULER_VEHICLE';
export const SEND_SCHEDULER_DISPATCH  = 'SEND_SCHEDULER_DISPATCH';

export const GET_PROJECT = 'GET_PROJECT';
export const SET_PROJECT = 'SET_PROJECT';
export const UPDATE_PROJECT  = 'UPDATE_PROJECT';

export const GET_DASHBOARD_PROJECTS = 'GET_DASHBOARD_PROJECTS'
