import { AnyAction } from 'redux';
import { Technician, TechnicianLog } from '../entities/technicians';
import { SET_TECHNICIANS, SET_TECHNICIANS_LOGS } from '../constants/technicians';

export interface TechniciansState {
  techinicians: Technician[];
  logs: TechnicianLog[];
}

const initialState: TechniciansState = {
  techinicians: [],
  logs: [],
};

export default function reducer (state: TechniciansState = initialState, action: AnyAction): TechniciansState {
  switch (action.type) {
    case SET_TECHNICIANS:
      return { ...state, techinicians: action.payload };

    case SET_TECHNICIANS_LOGS:
      return { ...state, logs: action.payload };

    default:
      return state;
  }
}
