/**
 * @author Saki
 * @date 2019-03-08 09:19:25
 * @Last Modified by: Saki
 * @Last Modified time: 2019-03-08 10:31:00
 *
 * Grid with unplanned tasks. This is just a react wrapper.
 */
// libraries
import React, { Component } from 'react';

// our stuff
import UnplannedGrid from './lib/UnplannedGrid.js';
import Task from './lib/Task.js';

class Grid extends Component {

    componentDidMount() {
        this.unplannedGrid = new UnplannedGrid({
            appendTo   : 'unplannedContainer',
            eventStore : this.props.eventStore,
            store      : {
                modelClass : Task,
                data: this.props.data,
                autoLoad   : true
            },
            columns : this.props.columns,
            features : {
                cellEdit     : false,
            },
        });
    } // eo function componentDidMount

    render() {
        return (<div id="unplannedContainer"></div>);
    } // eo function render

}; // eo class Grid

export default Grid;

// eof
