import "bryntum-scheduler/scheduler.stockholm.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./customPicker.css";

import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { BryntumScheduler } from "bryntum-react-shared";
import { RouteComponentProps } from "react-router";
import * as queryString from "query-string";
import {
  Form,
  Tabs,
  Tab,
  Row,
  Col,
  Container,
  Button,
  Alert
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Modal from "react-modal";
import { Formik } from "formik";
import Select from "react-select";
import * as Yup from "yup";

import { request } from "../../sagas/index";
import * as SchedulerActions from "../../actions/scheduler";
import { AppState, DispatchActions } from "../../reducers";
import {
  SchedulerTechnician,
  SchedulerProject
} from "../../entities/scheduler";

import Grid from "./Grid";
import TaskStore from "./lib/TaskStore.js";
import Drag from "./lib/Drag";
import NotesTable from "./NotesTable";

const Datetime = require('react-datetime');

const eventStore = new TaskStore();

Modal.setAppElement("#root");

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    margin: "0 10px"
  },
  overlay: {
    zIndex: 99,
    backgroundColor: "#0e0c0cbf"
  }
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  .b-virtual-scroller::-webkit-scrollbar-track,
  .b-widget-scroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2) !important;
    border-radius: 0px !important;
    background-color: #f5f5f5 !important;
  }
  .b-virtual-scroller::-webkit-scrollbar,
  .b-widget-scroller::-webkit-scrollbar {
    width: 12px !important;
    background-color: #f5f5f5 !important;
  }
  .b-virtual-scroller::-webkit-scrollbar-thumb,
  .b-widget-scroller::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #831f85 !important;
  }

  #unplannedContainer {
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #d8d9da;
  }
  .b-sch-event {
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
    padding-right: 10px;
  }
  .b-sch-event section {
    overflow: hidden;

    display: block;
  }

  .b-react-scheduler-container {
    flex: 1;
    overflow: hidden;
    border-top: 1px solid #d8d9da;
  }

  #unplannedContainer {
    width: 300px;
  }

  .b-grid-header-text-content {
    color: #831f85;
    font-weight: 600;
    font-size: 1.1em;
    margin-left: 5px;
  }

  .b-sch-header-timeaxis-cell {
    text-transform: none;
    font-weight: bold;
    color: #97549a;
  }

  .b-sch-header-row-top {
    text-transform: uppercase;
  }

  .b-grid {
    flex: none;
    border-left: 2px solid #aaa;
  }

  .b-grid-cell {
    cursor: pointer;
  }

  dl {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  i {
    margin-right: 10px;
    color: #4887e5;
  }

  .b-grid-header {
    height: 57px;
  }

  .b-sch-event.b-drag-invalid {
    background: red;
  }

  .b-unassigned-class.b-drag-proxy:not(.b-drag-invalid) {
    background: green;
  }

  .b-aborting.b-unassigned-class {
    transition: transform 0.3s !important;
    background: red;
    position: absolute !important;
    z-index: 10000;
    pointer-events: none;
    opacity: 0.8;
    box-sizing: border-box;
  }

  .b-grid {
    flex: none;
    border-left: 1px solid #d2d2d2;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #f0f7f7;

  input {
    margin: 10px;
    height: 46px;
    font-size: 19px;
    padding: 0 10px;
    border-color: #0000001a;
    border-radius: 0.25rem !important;
    border: 1px solid #cbc8d0;
    color: #831f85;
  }
`;

const FormGroup = styled(Form.Group)`
  .form-control {
    height: 46px;
    width: 250px;
    margin-top: 11px;
    font-size: 19px;
    border-color: #0000001a;
    border-radius: 0.25rem !important;
    color: #831f85;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form-group {
    width: calc(100% / 3 - 20px);
    margin: 10px;
  }
`;

const ModalContent = styled.div`
  min-width: 100vh;
`;

const SelectWrapper = styled(Select)`
  .react-select__control {
    ${props => (props.isInvalid ? "border-color: red !important;" : "")}
  }
`;

const FilterWrapper = styled.div`
  position: absolute;
  top: 125px;
  right: 39px;
  z-index: 4;
`;

interface SchedulerComponentState {
  startDate: any;
  endDate: any;
  selectedViewPreset: string;
  isOpen: boolean;
  event: any;
  resource: any;
  success: any;
  message: string;
  tabIndex: string;
  notes: any;
}

interface SchedulerProps {
  technicians: SchedulerTechnician[];
  projects: SchedulerProject[];
  unassigned: SchedulerProject[];
  eventsVersion: number;
  resourcesVersion: number;
  customFields: any;
  vehicle: any;
  project: any;
}

interface DispatchToProps {
  actions: DispatchActions;
}

const mapStateToProps = (state: AppState): SchedulerProps => ({
  technicians: state.scheduler.techinicians,
  projects: state.scheduler.projects,
  unassigned: state.scheduler.unassigned,
  eventsVersion: state.scheduler.eventsVersion,
  resourcesVersion: state.scheduler.resourcesVersion,
  customFields: state.scheduler.customFields,
  vehicle: state.scheduler.vehicle,
  project: state.scheduler.project
});

const mapDispatchToProps = (
  dispatch: Dispatch
): { actions: DispatchActions } => ({
  actions: bindActionCreators(SchedulerActions, dispatch)
});

type SchedulerComponentProps = SchedulerProps &
  DispatchToProps &
  RouteComponentProps;

class Scheduler extends React.Component<
  SchedulerComponentState & SchedulerComponentProps
  > {
  state = {
    startDate: moment(),
    endDate: moment().add(1, "day"),
    selectedViewPreset: "hourAndDay",
    dateView: "hour",
    isOpen: false,
    isOpenSearch: false,
    isOpenDash: false,
    resource: {},
    event: {},
    success: null,
    message: "",
    tabIndex: "0",
    notes: [],
    loading: false,
    projectStatus: [],
    unassigned: this.props.unassigned,
  };

  schedulerRef: any = React.createRef();
  grid: any = React.createRef();
  timeout: any;

  fetchingInterval: any;

  componentWillMount() {
    const {
      location: { search }
    } = this.props;
    const { startDate, endDate } = queryString.parse(search);
    if (startDate && endDate) {
      this.setState({
        startDate: moment(startDate, "MM-DD-YYYY"),
        endDate: moment(endDate, "MM-DD-YYYY")
      });
    }
  }

  async componentWillReceiveProps(nextProps: any) {
    console.log('componentWillReceiveProps', nextProps);
    this.setState({ unassigned: nextProps.unassigned });
    if (this.refs && this.refs.grid) {
      const { grid }: any = this.refs
      grid.unplannedGrid.data = nextProps.unassigned
    } 
    // const { grid }: any = this.refs;
    // if (nextProps.unassigned) grid.store.reload()
  }

  async componentDidMount() {
    const {
      actions,
      location: { search }
    } = this.props;
    const { t, view, startDate, endDate } = queryString.parse(search);
    const { schedulerEngine } = this.schedulerRef.current;
    actions.getSchedulerTechnicians(t);
    actions.getSchedulerUnassignedProjects(t);
    const q = `&projectRefNumber=123`
    // actions.getDashBoardProjects(search, q)
    this.fetchProjects();

    const projectStatus = await request.get(`/projects/status/get?t=${t}`);
    const technicianStatus = await request.get(`/ux/projects/technician/status?t=${t}`);

    this.setState({
      projectStatus: projectStatus.data.resources,
      technicianStatus: technicianStatus.data.resources,
    });

    const configStart = moment(startDate);
    const configEnd = moment(endDate);
    const days = moment.duration(configEnd.diff(configStart)).asDays();

    if (configStart.isValid() && configEnd.isValid() && startDate && endDate) {
      if (view === "day") {
        // This is to fix the issue where scheduler is complaining if the start/end date difference is <= 0
        const addXdays = (days <= 5 ? 1 : 0);
        
        schedulerEngine.setTimeSpan(
          configStart.format("YYYY-MM-DD"),
          configEnd.clone().add(addXdays, "day").format("YYYY-MM-DD"),
          false
        );

        schedulerEngine.viewPreset = "hourAndDay";
        this.setState({
          startDate: configStart,
          endDate: configEnd,
          dateView: "week"
        });
      }  else if (view === "week") {
        // Check whether the date from the query is more than 7 days
        if (days >= 6) {
          schedulerEngine.setTimeSpan(
            configStart.format("YYYY-MM-DD"),
            configEnd.clone().format("YYYY-MM-DD"),
            false
          );

          schedulerEngine.viewPreset = "weekAndDay";
          this.setState({
            startDate: configStart,
            endDate: configEnd,
            dateView: "week"
          });
        }
      }
    } else {
      const startD = moment();
      const endD = moment().add(2, "day");

      if (view == "day") {
        schedulerEngine.setTimeSpan(
          startD.format("YYYY-MM-DD"),
          endD.format("YYYY-MM-DD"),
          false
        );

        schedulerEngine.viewPreset = "hourAndDay";
        this.setState({
          startDate: startD,
          endDate: endD,
          dateView: "hour"
        });
      }
    }

    this.fetchingInterval = setInterval(this.fetchProjects, 15000);
  }

  componentWillUnmount() {
    clearInterval(this.fetchingInterval);
  }

  componentDidUpdate() {
    const {
      actions,
      location: { search }
    } = this.props;
    const { t } = queryString.parse(search);
    const { grid }: any = this.refs;
    if (grid && this.schedulerRef.current) {
      const { schedulerEngine } = this.schedulerRef.current;
      new Drag({
        grid: grid.unplannedGrid,
        schedule: schedulerEngine,
        constrain: false,
        outerElement: grid.unplannedGrid.element,
        onDragDropped: (task: any) => {
          actions.updateSchedulerProject(task.resource.data, task.data, t);
        }
      });
    }
  }

  fetchProjects = () => {
    const {
      actions,
      location: { search }
    } = this.props;
    const { startDate, endDate } = this.state;
    const { t } = queryString.parse(search);
    actions.getSchedulerProjects(
      t,
      startDate.clone().format("MM-DD-YYYY"),
      endDate.clone().format("MM-DD-YYYY")
    );
  };

  onEventDrop = (event: any) => {
    const {
      actions,
      location: { search }
    } = this.props;
    const { t } = queryString.parse(search);
    actions.updateSchedulerProject(
      event.targetResourceRecord.data,
      event.context.record.data,
      t,
      event.context.resourceRecord.data
    );
  };

  onEventResized = (event: any) => {
    const {
      actions,
      location: { search }
    } = this.props;
    const { t } = queryString.parse(search);
    actions.updateSchedulerProject(
      event.eventRecord.resource.data,
      event.eventRecord.data,
      t
    );
  };

  onEventClick = async (event: any) => {
    const { resourceRecord, eventRecord } = event;

    const {
      actions,
      location: { search }
    } = this.props;
    const { t, u, userid } = queryString.parse(search);

    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
      resource: resourceRecord.data,
      event: eventRecord.data
    });

    actions.getSchedulerVehicle(t, u, eventRecord.data.employeeId);
    actions.getSchedulerFieldValues(t, eventRecord.data);
    actions.getProject(t, eventRecord.data.projectId, userid);

    const {
      data: { resources }
    } = await request.get(
      `/notes?t=${t}&projectID=${eventRecord.data.projectId}`
    );
    this.setState({ notes: resources });
  };

  onDatesChange = (e: any, { startDate, endDate }: any) => {
    const { schedulerEngine } = this.schedulerRef.current;

    if (startDate && endDate) {
      let newEndDate = moment();

      if (startDate.clone().format("L") === endDate.clone().format("L")) {
        newEndDate = endDate.add(1, "day").format("YYYY-MM-DD");
      } else {
        newEndDate = endDate.clone().format("YYYY-MM-DD")
      }

      schedulerEngine.setTimeSpan(
        startDate.format("YYYY-MM-DD"),
        newEndDate,
        false
      );

      this.setState({
        startDate,
        endDate,
        // selectedViewPreset: "weekAndDay",
        dateView: "default"
      }, this.fetchProjects);
    }
  };

  onChangeDateView = (e: any) => {
    const { schedulerEngine } = this.schedulerRef.current;
    const dateView = e.target.value;

    let newState = {
      startDate: moment(),
      endDate: moment(),
      selectedViewPreset: "hourAndDay",
      dateView: ""
    };

    if (dateView === "default") {
      newState = {
        startDate: moment().clone(),
        endDate: moment().clone().add(1, "day"),
        selectedViewPreset: "hourAndDay",
        dateView
      };
    } else if (dateView === "day") {
      newState = {
        startDate: moment().clone(),
        endDate: moment().clone().add(1, "day"),
        selectedViewPreset: "weekAndDay",
        dateView
      };
    } else if (dateView === "week1") {
      newState = {
        startDate: moment().clone().startOf("week"),
        endDate: moment().clone().endOf("week"),
        selectedViewPreset: "weekAndDay",
        dateView
      };
    } else if (dateView === "week2") {
      newState = {
        startDate: moment().clone(),
        endDate: moment().clone().add(2, "weeks"),
        selectedViewPreset: "weekAndDay",
        dateView
      };
    } else if (dateView === "month") {
      newState = {
        startDate: moment().clone(),
        endDate: moment().clone().add(1, "month"),
        selectedViewPreset: "weekAndDay",
        dateView
      };
    }

    schedulerEngine.setTimeSpan(
      moment().format("YYYY-MM-DD"),
      newState.endDate.add(1, "day").format("YYYY-MM-DD"),
      true
    );

    this.setState({
      ...this.state,
      ...newState
    }, this.fetchProjects);
  }

  onChangeTodayView = () => {
    const newState = {
      startDate: moment().clone(),
      endDate: moment().add(1, "day").clone(),
      selectedViewPreset: "hourAndDay",
      dateView: "hour"
    };

    this.setState({
      ...this.state,
      ...newState
    }, this.fetchProjects);
  };

  onChangeValue = (field: string, value: string) => {
    const { event }: any = this.state;
    const {
      actions,
      location: { search }
    } = this.props;
    const { t } = queryString.parse(search);

    actions.updateSchedulerCustomFieldValues(t, field, value, event.projectId);
  };

  renderErrorMessage = () => {
    const { success, message } = this.state;
    return message ? (
      <Alert variant={success ? "success" : "danger"}>{message}</Alert>
    ) : null;
  };

  renderEditForm = () => {
    const {
      actions,
      project,
      location: { search }
    } = this.props;
    const { event, projectStatus, technicianStatus }: any = this.state;
    const { t, userid } = queryString.parse(search);

    let initialValues = {
      primaryContactName: "",
      primaryContactPhone: "",
      clientPORef: "",
      specialComments: "",
      jobDescription: "",
      projectStatusID: "",
      technicianStatusID: "",
      appointmentDate: "",
    };

    if (project) {
      initialValues = project;
    }

    return (
      <Formik
        ref="editForm"
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          primaryContactName: Yup.string()
            .required("Required")
            .nullable(),
          primaryContactPhone: Yup.string()
            .required("Required")
            .nullable(),
        })}
        onSubmit={(values, { resetForm }) => {
          const {
            primaryContactName,
            primaryContactPhone,
            clientPORef,
            specialComments,
            jobDescription,
            projectStatusID,
            technicianStatusID,
            appointmentDate,
          }: any = values;

          this.setState({ loading: true });

          actions.updateProject(
            {
              t,
              UserID: userid,
              ProjectID: event.projectId,
              primaryContactName,
              primaryContactPhone,
              clientPORef,
              specialComments,
              jobDescription,
              projectStatusID,
              technicianStatusID,
              appointmentDate: appointmentDate,
            },
            ({ success, message }: any) => {
              this.setState({ success, message, loading: false });
            }
          );
        }}
      >
        {({ handleSubmit, setFieldValue, values, errors, touched }) => {
          const {
            primaryContactName,
            primaryContactPhone,
            clientPORef,
            specialComments,
            jobDescription,
            projectStatusID,
            technicianStatusID,
            appointmentDate,
          } = values;
          return (
            <form onSubmit={handleSubmit}>
              <Container className="pl-2 mt-2">
                {this.renderErrorMessage()}
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Project Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={projectStatusID}
                        onChange={(e: any) => setFieldValue("projectStatusID", e.target.value)}
                      >
                        {_.map(projectStatus, option => (
                          <option
                            key={option.projectStatusID}
                            value={option.projectStatusID}
                          >
                            {option.displayName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Techinician Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={technicianStatusID}
                        onChange={(e: any) => setFieldValue("technicianStatusID", e.target.value)}
                      >
                        {_.map(technicianStatus, option => (
                          <option
                            key={option.id}
                            value={option.id}
                          >
                            {option.displayName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Apointment Date</Form.Label>
                      <Datetime value={appointmentDate} onChange={(dateTime: any) => setFieldValue("appointmentDate", dateTime)} />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Contact Name</Form.Label>
                      <Form.Control
                        value={primaryContactName}
                        onChange={(e: any) =>
                          setFieldValue("primaryContactName", e.target.value)
                        }
                        isInvalid={
                          !!errors.primaryContactName &&
                          touched.primaryContactName
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.primaryContactName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Contact Phone Number</Form.Label>
                      <Form.Control
                        value={primaryContactPhone}
                        onChange={(e: any) =>
                          setFieldValue("primaryContactPhone", e.target.value)
                        }
                        isInvalid={
                          !!errors.primaryContactPhone &&
                          touched.primaryContactPhone
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.primaryContactPhone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Cient P.O. Ref#</Form.Label>
                      <Form.Control
                        value={clientPORef}
                        onChange={(e: any) =>
                          setFieldValue("clientPORef", e.target.value)
                        }
                        isInvalid={!!errors.clientPORef && touched.clientPORef}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.clientPORef}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Internal Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="7"
                        value={specialComments}
                        onChange={(e: any) =>
                          setFieldValue("specialComments", e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Job Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="7"
                        value={jobDescription}
                        onChange={(e: any) =>
                          setFieldValue("jobDescription", e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Send
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={ () => this.setState({ isOpen: false }) }
                  variant="danger"
                >
                  Close
                </Button> */}
              </Container>
            </form>
          );
        }
        }
      </Formik >
    );
  };

  renderNotesForm = () => {
    const { notes }: any = this.state;
    const {
      location: { search }
    } = this.props;
    const { t } = queryString.parse(search);
    return (
      <div className="pl-2 mt-2">
        <Formik
          enableReinitialize
          ref="notesForm"
          onSubmit={async ({ notes }, { resetForm }) => {
            const { event }: any = this.state;
            const {
              location: { search }
            } = this.props;
            const { t } = queryString.parse(search);
            const { data: { resources } } = await request.post(`/notes/post`, {
              t,
              notes,
              projectID: event.projectId,
              employeeID: event.employeeId
            });
            resetForm();
            this.setState((state: any) => {
              return {
                notes: [{ notes, noteID: resources, createdDate: moment().format("DD-MMM-YYYY hh:mm A") }, ...state.notes],
              }
            });
          }}
          initialValues={{ notes: "" }}
          validationSchema={Yup.object().shape({
            notes: Yup.string()
              .required("Required")
              .nullable()
          })}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            const { notes } = values;
            return (
              <form onSubmit={handleSubmit}>
                <Form.Group>
                  <div className="row px-15">
                    <Form.Control
                      className="col-form"
                      as="textarea"
                      rows="1"
                      value={notes}
                      maxLength={255}
                      onChange={(e: any) =>
                        setFieldValue("notes", e.target.value)
                      }
                      isInvalid={!!errors.notes && touched.notes}
                    />
                    <Button className="btn btn-light radius-3 col-btn" variant="primary" type="submit">
                      Submit Note
                  </Button>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.notes}
                  </Form.Control.Feedback>
                </Form.Group>
              </form>
            );
          }}
        </Formik>
        <div className="notes-table">
          <NotesTable
            columns={notes}
            tenantID={t}
            onUpdate={(values: any, selectedIndex: any) => {
              notes[selectedIndex].notes = values.notes;
              this.setState({ notes });
            }}
          />
        </div>
      </div>
    );
  };

  renderDispatchForm = () => {
    const { vehicle, project } = this.props;

    let initialValues = {
      options: [],
      notes: ""
    };

    if (project && project.projectRefNumber) {
      const { projectRefNumber, clientName, location } = project;
      initialValues.notes = `${projectRefNumber} - ${clientName} - ${location}`;
    }

    return (
      <Formik
        enableReinitialize
        ref="dispatchForm"
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          options: Yup.array()
            .required("Required")
            .nullable(),
          notes: Yup.string()
            .required("Required")
            .nullable()
        })}
        onSubmit={({ options, notes }, { resetForm }) => {
          const {
            actions,
            location: { search }
          } = this.props;
          const {
            event: { projectId }
          }: any = this.state;
          const { t, userid } = queryString.parse(search);

          this.setState({ loading: true });

          actions.sendSchedulerDispatch(
            t,
            projectId,
            userid,
            notes,
            options,
            ({ success, message }: any) => {
              this.setState({ success, message, loading: false });
              resetForm();
            }
          );
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          const { options, notes } = values;
          return (
            <form onSubmit={handleSubmit} className="pl-2 pr-2 mt-2">
              {this.renderErrorMessage()}
              <Form.Group>
                <Form.Label>Select Technician</Form.Label>
                <SelectWrapper
                  isMulti
                  value={options}
                  placeholder=""
                  options={vehicle.map(({ id, displayName }: any) => ({
                    value: id,
                    label: displayName
                  }))}
                  onChange={(values: any) => setFieldValue("options", values)}
                  classNamePrefix="react-select"
                  isInvalid={!!errors.options && touched.options}
                />
                <div style={{ fontSize: 12, color: "#f44336" }}>
                  {errors.options && touched.options ? errors.options : ""}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={notes}
                  maxLength={255}
                  onChange={(e: any) => setFieldValue("notes", e.target.value)}
                  isInvalid={!!errors.notes && touched.notes}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.notes}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Button className="btn btn-primary radius-3 px-4 py-2" variant="primary" type="submit" disabled={isSubmitting}>
                Send
              </Button>
              <Button
                style={{ marginLeft: 5 }}
                onClick={ () => this.setState({ isOpen: false }) }
                variant="danger"
              >
                Close
              </Button> */}
            </form>
          );
        }}
      </Formik>
    );
  };

  renderProjectForm = () => {
    const { location: { search } } = this.props;
    const { t, u, userid } = queryString.parse(search);
    const detailsUrl = `https://www.snapsuite.io/?t=${t}&u=${u}&userId=${userid}`

    return (
      <div>
        <div className="modalHeader">
          <button
            type="button"
            className="modalClose"
            onClick={() => this.setState({ isOpen: false })}
          >
            <span>&times;</span>
          </button>
          <h4>Event name</h4>
          <h6 style={{ margin: 0 }}>
            Event Description
          </h6>
        </div>
        <ModalContent className="modalBody">
          <iframe src={detailsUrl} width="100%" height="100%" frameBorder="0" /> 
        </ModalContent>
        <div className="modalFooter px-3 py-3">
          <Button
            className="btn btn-light radius-3 px-4 py-2 ml-2"
            variant="primary"
            type="submit"
            onClick={() => this.setState({ isOpen: false })}>

            Cancel
          </Button>
        </div>
      </div>
    );
  }

  // @NOTE: To deprecate
  renderProjectFormTmp = () => {
    const { event, tabIndex, loading }: any = this.state;
    const {
      customFields,
      location: { search }
    } = this.props;
    const { customFormFields, projectId } = event;

    const { t, u, userid } = queryString.parse(search);
    const qs = queryString.stringify({
      t,
      u,
      pid: projectId,
      userid,
      iniframe: true,
      moduleId: 2,
      navigationId: 23,
      ActionName: "WorkOrder"
    });

    const detailsUrl = `https://portal.snapsuite.io/projects/details/${projectId}`;
    const initialValues: any = {};

    _.forEach(customFields, (field, index) => {
      initialValues[index] = field.value || field.selectedValue;
    });

    return (
      <div>
        <div className="modalHeader">
          <button
            type="button"
            className="modalClose"
            onClick={() => this.setState({ isOpen: false })}
          >
            <span>&times;</span>
          </button>
          <h4>{event.name && event.name.replace(/<[^>]*>?/gm, '')}</h4>
          <h6 style={{ margin: 0 }}>
            {`${event.projectRefnumber} | `}
            <span>{event && event.htmlViewStatus && event.htmlViewStatus.replace(/<[^>]*>?/gm, '')}</span>
            {` | `}
            <span dangerouslySetInnerHTML={{ __html: event.priority }} />
          </h6>
        </div>
        <ModalContent className="modalBody">
          <Tabs
            onSelect={(tabIndex: any) =>
              this.setState({ tabIndex, success: null, message: "" })
            }
            defaultActiveKey="0"
            id="formTabs"
            // ref="modalTabs"
          >
            <Tab eventKey={0} title="Dispatch">
              {this.renderDispatchForm()}
            </Tab>
            <Tab eventKey={1} title="Quick Edit">
              {this.renderEditForm()}
            </Tab>
            <Tab eventKey={2} title="Custom">
              <FormWrapper>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={() => { }}>

                  {({ values, setFieldValue }) => {
                    return (
                      customFormFields &&
                      _.map(
                        customFormFields,
                        (
                          fields: any,
                          index: string
                        ): React.ReactElement | null => {
                          if (!fields.isVisible) return null;

                          let formControl;

                          if (fields.inputType === "dropdown") {
                            formControl = (
                              <Form.Control
                                as="select"
                                value={values[index]}
                                name={index}
                                onChange={(e: any) => {
                                  setFieldValue(index, e.target.value);
                                  this.onChangeValue(index, e.target.value);
                                }}
                              >
                                {customFields[index] &&
                                  _.map(customFields[index].list, option => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                              </Form.Control>
                            );
                          } else {
                            formControl = (
                              <Form.Control
                                type="text"
                                value={values[index]}
                                name={index}
                                onChange={(e: any) => {
                                  setFieldValue(index, e.target.value);
                                }}
                                onBlur={(e: any) => {
                                  if (e.target.value)
                                    this.onChangeValue(index, e.target.value);
                                }}
                              />
                            );
                          }
                          return (
                            <Form.Group key={index}>
                              <Form.Label>{fields.displayName}</Form.Label>
                              {formControl}
                            </Form.Group>
                          );
                        }
                      )
                    );
                  }}
                </Formik>
              </FormWrapper>
            </Tab>
            <Tab eventKey={3} title="Notes">
              {this.renderNotesForm()}
            </Tab>
          </Tabs>
        </ModalContent>
        <div className="modalFooter px-3 py-3">
          <Button
            className="btn btn-primary radius-3 px-4 py-2 mr-2"
            variant="primary"
            onClick={() => {
              const { modalTabs, dispatchForm, editForm }: any = this.refs;
              // const { activeKey } = modalTabs.props;
              if (tabIndex === "0") {
                dispatchForm.submitForm();
              } else if (tabIndex === "1") {
                editForm.submitForm();
              } else {
                console.log("[info] Unknown action. Selected tab - ", tabIndex)
              }

              this.setState({
                success: null,
                message: "",
              });
            }}
            disabled={loading}
          >
            {loading ? (
              <span>
                <i className="fa fa-spinner fa-spin" /> {tabIndex === "0" ? "Sending..." : "Saving..."}
              </span>
            ) :
              tabIndex === "0" ? "Dispatch" : "Save"}
          </Button>
          <Button
            className="btn btn-light radius-3 px-4 py-2 ml-2"
            variant="primary"
            type="submit"
            onClick={() => this.setState({ isOpen: false })}>

            Cancel
          </Button>
          <a
            target="_blank"
            href={detailsUrl}
            className="radius-3 px-4 py-2 ml-2 btn btn-outline-primary"
            style={{ float: "right" }}
          >
            View Full Details
          </a>
        </div>
      </div>
    );
  };

  onSearch = async (value: string) => {
    const {
      actions,
      location: { search }
    } = this.props;
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const { t } = queryString.parse(search);
      const q = `&projectRefNumber=${value}`
      if (!value) {
        this.onFilterBy('')
        return
      }
      actions.getDashBoardProjects(search, q)
    }, 500)
  }

  onSortBy = (sortBy: string) => {
    const {
      actions,
      location: { search }
    } = this.props;
    const { t } = queryString.parse(search);
    actions.getSchedulerUnassignedProjects(t, sortBy);
  }

  onPrint = () => {
    const { startDate, endDate } = this.state;
    const {
      actions,
      location: { search }
    } = this.props;
    const { t } = queryString.parse(search);
    console.log("Tenant", t);
    console.log("StartDate", startDate.format("YYYY/MM/DD"));
    console.log("EndDate", endDate.format("YYYY/MM/DD"));
    const baseUrl = "https://portal.snapsuite.io/print?"
    const queryStringStartDate = "startDate=" + startDate.format("MM/DD/YYYY");
    const queryStringEndDate = "&endDate=" + endDate.format("MM/DD/YYYY");
    const queryStringTenant = "&t=" + t;
    const printUrl = baseUrl + queryStringStartDate + queryStringEndDate + queryStringTenant;

    window.open(printUrl, "_blank")
  }

  onFilterBy = (qs: string) => {
    const {
      actions,
      location: { search }
    } = this.props;
    const { t } = queryString.parse(search);
    actions.getSchedulerUnassignedProjects(t, null, qs);
  }

  renderButtons = () => {
    <Container>
      <Button
        variant="primary"
        style={{
          height: 43,
          marginTop: 13,
          marginRight: 10,
          fontSize: 18
        }}
        className="radius-3"
      // onClick={this.onChangeTodayView}
      >
        Button 1
          </Button>
    </Container>
  }

  render() {
    const {
      technicians,
      projects,
      unassigned,
      eventsVersion,
      resourcesVersion,
      actions
    } = this.props;

    const { dateView, isOpen, isOpenSearch, isOpenDash, selectedViewPreset } = this.state;
    const schedulerStartDate = this.state.startDate;
    const schedulerEndDate = this.state.endDate;

    const eventTooltipFeature = {
      template: ({ eventRecord }: any) => {
        const { data } = eventRecord;
        return `
          <header class="b-panel-header b-popup-header b-tooltip-header b-dock-top ">${data.name}</header>
          <div class="row b-tooltip-cont">
            <div class="col-6 pr-0">
              <div class="d-flex mb-2">
                <strong class="w-90px">Project Ref:</strong>
                <span>${data.projectRefnumber}</span>
              </div>
              <div class="d-flex mb-2">
                <strong class="w-90px">Due Date:</strong>
                <span>${data.displayDueDate} &nbsp;${data.priority}</span>
             </div>             
            </div>

            <div class="col-6 pr-0">
              <div class="d-flex mb-2">
                <strong class="w-110px">Priority/Status:</strong>
                <span class="priority">&nbsp;${data.htmlViewStatus}</span>
              </div>
              <div class="d-flex mb-2">
                <strong class="w-90px">Tech. Status:</strong>
                <span>${ data.htmlViewTechnicianStatus}</span>
              </div>
            </div>
            <div class="col-12 mt-2 mb-2">
              <span>${data.notes}</span>
            </div>
          </div>
        `;
      }
    };

    const eventContextMenuFeature = {
      items: {
        deleteEvent: false,
        creatEvent: false,
        unassignEvent: false
      }
    };

    //Scheduler Event Box 
    const eventBodyTemplate = ({ data }: any) => {
      const date = moment(data.endDate).format("MMM DD");
      return `
        <!--<i class="cancelled sched-icon"></i>-->
        <section class="${data.htmlColourSchemeClassStatus}">
          <div class="label-title">${data.name && data.name.replace(/<[^>]*>?/gm, '')}</div>
          <div>
            <span>${data.projectRefnumber}</span>
            ${data.htmlViewTechnicianStatus} <span>@${data.displayDueDate}</span>
          </div>
        </section>
      `;
    };

    //Dynamically Control the text and background colour
    const eventRenderer = ({ eventRecord: { data }, tplData }: any) => {
      tplData.style = `background-color: ${data.htmlColourStatus};`;
      tplData.cls.add(`${data.htmlColourSchemeClassLeftBorder}`); //Added custom style to control text colour based on background.
      return { data };
    };

    const listeners = {
      eventDrop: this.onEventDrop,
      eventResizeEnd: this.onEventResized,
      eventclick: this.onEventClick,
    };

    const _configureWorkingTimes = () => {
      return {
        "fromHour" : 6,
        "toHour"   : 19
      };      
    };

    const _configureHeaders = () => {
      if (dateView === "hour" || dateView === "default") {
        return {}
      } else if (dateView === "week") {
        return {
          headerConfig: {
            middle: {
              unit: "day",
              dateFormat: "DD - ddd"
            },
            top: {
              unit: "week",
              dateFormat: "MMMM YYYY"
            }
          },
          columnLinesFor: "middle"
        }
      } else {
        return {
          headerConfig: {
            middle: {
              unit: "day",
              dateFormat: "DD - ddd"
            },
            top: {
              unit: "month",
              dateFormat: "MMMM YYYY"
            }
          },
          columnLinesFor: "middle"
        }
      }
    };

    return (
      <div>
        <div className="search-icons position-absolute">
          {/* <div className="d-flex">
            <a className="radius-3 px-2 py-2 ml-2 btn btn-outline-primary btn-outline-primary_2 clickable" onClick={() => this.setState({ isOpenSearch: true })}><i className="fa fa-search"></i>Search</a>
            <a className="radius-3 px-2 py-2 ml-2 btn btn-outline-primary btn-outline-primary_2 clickable" onClick={() => this.setState({ isOpenDash: true })}><i className="fa fa-th-large"></i>Dashboard</a>
            <a className="radius-3 px-2 py-2 ml-2 btn btn-outline-primary btn-outline-primary_2 clickable" onClick={() => this.setState({ isOpenDash: true })}><i className="fa fa-th-large"></i>Print</a>
          </div> */}
        </div>
        <DateWrapper>
          <Button
            variant="primary"
            style={{
              height: 43,
              marginTop: 13,
              marginRight: 10,
              fontSize: 18
            }}
            className="radius-3"
            onClick={this.onChangeTodayView}
          >
            Today
          </Button>
          <FormGroup>
            <Form.Control
              as="select"
              value={dateView}
              onChange={this.onChangeDateView}
            >
              <option value="default">Change View</option>
              {/* <option value="hour">Hour</option> */}
              <option value="day">Today/Tomorrow</option>
              <option value="week1">Week</option>
              <option value="week2">Two Weeks</option>
              <option value="month">Month</option>
            </Form.Control>
          </FormGroup>
          <DateRangePicker
            autoUpdateInput={false}
            opens="left"
            startDate={schedulerStartDate}
            endDate={schedulerEndDate}
            onEvent={this.onDatesChange}
            // onApply={() => {
            //   this.setState({
            //     selectedViewPreset: "weekAndDay"
            //   })
            // }}
          >
            <input
              readOnly
              value={`${schedulerStartDate.format("MM/DD/YYYY")} - ${schedulerEndDate.format(
                "MM/DD/YYYY"
              )}`}
            />
          </DateRangePicker>
          <a className="radius-3 px-2 py-2 ml-2 btn btn-outline-primary btn-outline-primary_2 clickable print-btn" onClick={() => this.onPrint()}><i className="fa fa-print mr-2"></i>Print</a>
        </DateWrapper>
        <Wrapper>
          <BryntumScheduler
            ref={this.schedulerRef}
            id="schedulerContainer"
            eventsVersion={eventsVersion}
            resourcesVersion={resourcesVersion}
            minHeight="calc(100vh - 75px)"
            timeRangesFeature={true}
            barMargin={4}
            eventDragCreateFeature={false}
            eventEditFeature={false}
            eventStyle="plain"
            eventColor="orange"
            eventTooltipFeature={eventTooltipFeature}
            eventContextMenuFeature={eventContextMenuFeature}
            columns={[
              {
                type: "resourceInfo",
                imagePath: "users/",
                showImage: true,
                text: "Techinician",
                field: "name",
                width: 170,
                showEventCount: false,
                showRole: true
              }
            ]}
            viewPreset={{
              name: selectedViewPreset,
              displayDateFormat: "ddd MMM DD, YYYY HH:mm A",
              ..._configureHeaders() // Merge the header based on selectedView
            }}
            startDate={this.state.startDate.format("YYYY-MM-DD")}
            endDate={this.state.endDate.clone().format("YYYY-MM-DD")}
            autoAdjustTimeAxis={false}
            zoomKeepsOriginalTimespan={true}
            workingTime={_configureWorkingTimes()}
            zoomOnMouseWheel={false}
            eventStore={eventStore}
            resources={technicians}
            events={projects}
            eventBodyTemplate={eventBodyTemplate}
            eventRenderer={eventRenderer}
            listeners={listeners}
          />
          {unassigned.length ? (
            <>
              <Grid
                ref={`grid`}
                eventStore={eventStore}
                data={unassigned}
                columns={[
                  {
                    text: `<div class="unassigned-label">Unassigned Jobs</div>
                            `,
                    type: "template",
                    field: "name",
                    template: ({ value, record: { data } }: any) => {
                      const projnumber = data.projectRefNumber || data.projectRefnumber
                      const projectEarthUrl = `https://portal.snapsuite.io/projects/details/${data.projectId}`;

                      return `
                        <div class="right-panel ${data.htmlColourSchemeClassLeftBorder || ''}">
                          <div class="top-text">${projnumber} - ${value || ""}</div>
                          <div class="sub-text">
                           ${data.htmlViewStatus} @${data.displayDueDate}
                               <br/><a class="lnkdetails" target="_blank" href="${projectEarthUrl}">View Details</a> 
                          </div>
                        </div>
                      `;
                    }
                  }
                ]}
              /* {unassigned.length ? (
          <>
            <Grid
              ref={`grid`}
              eventStore={eventStore}
              data={unassigned}
              columns={[
                {
                  text: `<div class="unassigned-label">Unassigned Jobs</div>
                          `,
                  type: "template",
                  field: "name",
                  template: ({ value, record: { data } }: any) => {
                    const {
                      customDropDownType2,
                      customField5,
                    } = data.customFormFields;
                    return `
                      <div class="right-panel ${data.htmlColourSchemeClassLeftBorder}">
                        <div class="top-text">${data.projectRefnumber} - ${value || ""}</div>
                        <div class="sub-text">
                         ${data.htmlViewStatus} @${data.displayDueDate}
                             <br/><a class="lnkdetails" target="_blank" href="${data.projectEarthURL}">View Details</a> 
                        </div>
                        ${
                      customDropDownType2.customPropertyValue ||
                        customField5.customPropertyValue
                        ? `
                          <div>${customDropDownType2.customPropertyValue ||
                        ""} | ${customField5.customPropertyValue ||
                        ""}</div>
                        `
                        : ""
                      }
                      </div>
                    `;
                  }
                }
              ]} */
              />
              {/* WORK ON THIS */}
              <Form.Group className="unassigned-search">
                <Form.Control onChange={(e: any) => this.onSearch(e.target.value)} placeholder="Search.."></Form.Control>
              </Form.Group>
              <FilterWrapper className='filter-links'>Sort By:&nbsp;
                <a onClick={() => this.onSortBy('dueDate')} href='#'>Due Date</a> |
                <a onClick={() => this.onSortBy('location')} href='#'>Location</a> |
                <a onClick={() => this.onSortBy('projectID')} href='#'>Job ID</a> |
                {/* <a onClick={() => this.onFilterBy('&projectID=1&status=10')} href='#'>New Only</a> */}
                {/* <a onClick={() => this.onFilterBy('')} href='#'>Reset</a> */}
              </FilterWrapper>
            </>
          ) : (
              <div />
            )}
        </Wrapper>
        <Modal
          isOpen={isOpen}
          onRequestClose={() => {
            this.setState({
              isOpen: false,
              resource: {},
              event: {},
              message: "",
              success: null
            });
            actions.setSchedulerCustomFieldValues({});
            actions.setProject({});
          }}
          style={modalStyles}
        >
          {this.renderProjectForm()}
        </Modal>
        <Modal
          isOpen={isOpenSearch}
          style={modalStyles}
        >
          <div>
            <div className="modalHeader">
              <button
                type="button"
                className="modalClose"
                onClick={() => this.setState({ isOpenSearch: false })}
              >
                <span>&times;</span>
              </button>
              <h4 className="mb-0 text-bold">Search</h4>
              <span>Some text here</span>
            </div>
            <ModalContent className="modalBody">
              <div className="col-md-12 pl-0 pr-0">
                <div className="row m-0 w-100">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label>Label Here</Form.Label>
                      <Form.Control as="select">
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label>Label Here</Form.Label>
                      <Form.Control as="select">
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label>Label Here</Form.Label>
                      <Form.Control>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label>Label Here</Form.Label>
                      <Form.Control>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label>Label Here</Form.Label>
                      <Form.Control as="select">
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label>Label Here</Form.Label>
                      <Form.Control>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>

              </div>
            </ModalContent>
            <div className="modalFooter px-3 py-3 text-right">
              <Button className="btn btn-info radius-3 px-4 py-2 mr-2" variant="info">Reset</Button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isOpenDash}
          style={modalStyles}
        >
          <div>
            <div className="modalHeader">
              <button
                type="button"
                className="modalClose"
                onClick={() => this.setState({ isOpenDash: false })}
              >
                <span>&times;</span>
              </button>
              <h4 className="mb-0 text-bold">Dashboard</h4>
              <span>Some text here</span>
            </div>
            <ModalContent className="modalBody">
              <div className="col-md-12 pl-0 pr-0">
                <div className="row m-0 w-100">

                  <div className="col-md-3 col-sm-6 dash-grey-card_holder clickable">
                    <div
                      className="dash-grey-card c-card text-center py-2 px-3"
                    >
                      <p className="u-text-bold">Service Calls (Month)</p>
                      <div className="d-flex justify-between align-items-center">
                        <i className="fa fa-phone inner-icon mr-auto mt-0"></i>
                        <h1 className="text-bold mb-0">20</h1>
                      </div>
                      <span className="text-mute text-small">
                        <i className="fa fa-chevron-down text-danger mr-2"></i>
                        <span className="percent text-danger mr-2 utext-bold">55%</span>
                        Last Year Month
                    </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 dash-grey-card_holder clickable">
                    <div
                      className="dash-grey-card c-card text-center py-2 px-3"
                    >
                      <p className="text-bold">Service Calls (Month)</p>
                      <h1 className="text-bold mb-0">20</h1>
                      <span className="text-mute text-small">
                        <i className="fa fa-chevron-down text-danger mr-2"></i>
                        <span className="percent text-danger mr-2 text-bold">55%</span>
                        Last Year Month
                    </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 dash-grey-card_holder clickable">
                    <div
                      className="dash-grey-card c-card text-center py-2 px-3"
                    >
                      <p className="u-text-bold">Service Calls (Month)</p>
                      <div className="d-flex justify-between align-items-center">
                        <i className="fa fa-phone inner-icon mr-auto mt-0"></i>
                        <h1 className="text-bold mb-0">20</h1>
                      </div>
                      <span className="text-mute text-small">
                        <i className="fa fa-chevron-down text-danger mr-2"></i>
                        <span className="percent text-danger mr-2 utext-bold">55%</span>
                        Last Year Month
                    </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 dash-grey-card_holder clickable">
                    <div
                      className="dash-grey-card c-card text-center py-2 px-3"
                    >
                      <p className="text-bold">Service Calls (Month)</p>
                      <h1 className="text-bold mb-0">20</h1>
                      <span className="text-mute text-small">
                        <i className="fa fa-chevron-down text-danger mr-2"></i>
                        <span className="percent text-danger mr-2 text-bold">55%</span>
                        Last Year Month
                    </span>
                    </div>
                  </div>

                </div>
              </div>
            </ModalContent>
          </div>
        </Modal>


      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
