import { AnyAction } from 'redux';
import {
  GET_SCHEDULER_TECHNICIANS,
  GET_SCHEDULER_UNASSIGNED_PROJECTS,
  GET_DASHBOARD_PROJECTS,
  SET_SCHEDULER_UNASSIGNED_PROJECTS,
  SET_SCHEDULER_TECHNICIANS,
  GET_SCHEDULER_PROJECTS,
  SET_SCHEDULER_PROJECTS,
  GET_SCHEDULER_FIELD_VALUES,
  SET_SCHEDULER_FIELD_VALUES,
  UPDATE_SCHEDULER_FIELD_VALUES,
  UPDATE_SCHEDULER_PROJECT,
  ADD_SCHEDULER_PROJECT,
  SEND_SCHEDULER_DISPATCH,
  GET_SCHEDULER_VEHICLE,
  SET_SCHEDULER_VEHICLE,
  GET_PROJECT,
  SET_PROJECT,
  UPDATE_PROJECT,
} from '../constants/scheduler';
import { SchedulerTechnician, SchedulerProject } from '../entities/scheduler';

export function getSchedulerTechnicians (t: string): AnyAction {
  return {
    type: GET_SCHEDULER_TECHNICIANS,
    params: {
      t,
    },
  };
}

export function getSchedulerProjects (t: string, startDate: string, endDate: string): AnyAction {
  return {
    type: GET_SCHEDULER_PROJECTS,
    params: {
      t,
      startDate,
      endDate,
    },
  };
}

export function getSchedulerUnassignedProjects (t: string, sortBy?: string, query?: string): AnyAction {
  return {
    type: GET_SCHEDULER_UNASSIGNED_PROJECTS,
    params: {
      t,
      sortBy,
    },
    query,
  };
}

export function getDashBoardProjects (t: string, query?: string): AnyAction {
  return {
    type: GET_DASHBOARD_PROJECTS,
    params: {
      t,
    },
    query,
  };
}

export function setSchedulerTechnicians (payload: SchedulerTechnician[]): AnyAction {
  return {
    type: SET_SCHEDULER_TECHNICIANS,
    payload,
  };
}

export function setSchedulerProjects (payload: SchedulerProject[]): AnyAction {
  return {
    type: SET_SCHEDULER_PROJECTS,
    payload,
  };
}

export function setSchedulerUnassignedProjects (payload: SchedulerProject[]): AnyAction {
  return {
    type: SET_SCHEDULER_UNASSIGNED_PROJECTS,
    payload,
  };
}

export function updateSchedulerProject (technician: SchedulerTechnician, project: SchedulerProject, t: string, prevTechnician?: SchedulerTechnician): AnyAction {
  return {
    type: UPDATE_SCHEDULER_PROJECT,
    params: {
      technician,
      project,
      t,
      prevTechnician
    },
  };
}

export function addSchedulerProject (payload: SchedulerProject): AnyAction {
  return {
    type: ADD_SCHEDULER_PROJECT,
    payload,
  };
}

export function getSchedulerFieldValues (t: string, project: SchedulerProject): AnyAction {
  return {
    type: GET_SCHEDULER_FIELD_VALUES,
    params: {
      t,
      project,
    },
  };
}

export function setSchedulerCustomFieldValues (payload: any): AnyAction {
  return {
    type: SET_SCHEDULER_FIELD_VALUES,
    payload,
  };
}

export function updateSchedulerCustomFieldValues (t: string, propertyName: string, value: string, projectID: number): AnyAction {
  return {
    type: UPDATE_SCHEDULER_FIELD_VALUES,
    params: {
      t,
      propertyName,
      value,
      projectID,
    },
  };
}

export function sendSchedulerDispatch (t: string, projectID: number, employeeID: number, message: string, vehicleID: any, nextFunc?: any): AnyAction {
  return {
    type: SEND_SCHEDULER_DISPATCH,
    params: {
      t,
      projectID,
      employeeID,
      vehicleID,
      message,
    },
    nextFunc,
  };
}

export function getSchedulerVehicle (t: string, u: string, UserID: number): AnyAction {
  return {
    type: GET_SCHEDULER_VEHICLE,
    params: {
      t,
      u,
      UserID,
    },
  };
}

export function setSchedulerVehicle (payload: any): AnyAction {
  return {
    type: SET_SCHEDULER_VEHICLE,
    payload,
  };
}

export function getProject (t: string, ProjectID: number, UserID: number): AnyAction {
  return {
    type: GET_PROJECT,
    params: {
      t,
      ProjectID,
      UserID,
    },
  };
}

export function updateProject (payload: any, nextFunc?: any): AnyAction {
  return {
    type: UPDATE_PROJECT,
    payload,
    nextFunc,
  };
}

export function setProject (payload: any): AnyAction {
  return {
    type: SET_PROJECT,
    payload,
  };
}
