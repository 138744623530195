import * as React from 'react';
import { Formik } from 'formik';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

export interface TableHeader {
  label: string;
  field?: string;
  type?: string;
  placeholder?: string;
  options?: Option[];
  format?: (value: any) => void;
  component?: (column: any, index: number) => React.ReactElement;
  editStateComponent?: (column: any, index: number, formikBag?: any) => React.ReactElement;
}

export interface Option {
  value: any;
  label?: string;
}

export interface TableColumn {
  [key: string]: any;
}

export interface EditableTableProps {
  headers: TableHeader[];
  columns: TableColumn[],
  selectedColumn: TableColumn | null;
  selectedIndex: number | null;
  formSubmit?: any;
  validationSchema?: any;
}

interface FormFields {
  column: TableColumn;
  index: number;
  headers: TableHeader[];
  submit?: any;
  schema?: any;
}

const FormFields = ({ submit, schema, column, headers }: FormFields) => {
  return (
    <Formik
      initialValues={ column }
      validationSchema={ schema }
      onSubmit={ submit }
    >
      {(formikBag) => {
        const {
          values,
          errors,
          handleChange,
        } = formikBag;
        const formfields = headers.map((header, index) => {
          if (header.field) {
            if (header.type === 'checkbox') {
              return (
                <td key={ `form-field-${ index }` }>
                  <Form.Check
                    custom
                    checked={ values[header.field] }
                    onChange={ handleChange }
                    inline
                    label=''
                    type='checkbox'
                    id={ header.field }
                  />
                </td>
              );
            }

            if (header.type === 'select' && header.options) {
              return (
                <td key={ `form-field-${ index }` }>
                  <Form.Control as='select' name={ header.field } value={ values[header.field] } onChange={ handleChange }>
                    { header.options.map((option: Option, optionIndex) => (
                      <option key={ optionIndex } value={ option.value }>{ option.label }</option>
                    )) }
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    { errors[header.field] }
                  </Form.Control.Feedback>
                </td>
              );
            }

            return (
              <td key={ `form-field-${ index }` }>
                <Form.Control
                  type={ header.type }
                  name={ header.field }
                  placeholder={ header.placeholder }
                  onChange={ handleChange }
                  value={ values[header.field] }
                  isInvalid={ !!errors[header.field] }
                />
                <Form.Control.Feedback type='invalid'>
                  { errors[header.field] }
                </Form.Control.Feedback>
              </td>
            );
          } else if (header.editStateComponent) return <td key={ `form-field-${ index }` }>{ header.editStateComponent(column, index, formikBag) }</td>;
          return <td key={ `form-field-${ index }` } />;
        });
        return (
          <tr>
            { formfields }
          </tr>
        );
      }}
    </Formik>
  );
};

const EditableTable = ({ formSubmit, validationSchema, headers, columns, selectedColumn, selectedIndex }: EditableTableProps): React.ReactElement => {
  return (
    <Table responsive hover>
      <thead>
        <tr>
          { headers.map((header, index) => (
            <th key={ index }>{ header.label }</th>
          )) }
        </tr>
      </thead>
      <tbody>
        { columns.map((column, columnIndex) => {
          if (columnIndex === selectedIndex && selectedColumn) return (
            <FormFields
              submit={ formSubmit }
              key={ columnIndex }
              column={ selectedColumn }
              index={ columnIndex }
              headers={ headers }
              schema={ validationSchema }
            />
          );
          return (
            <tr key={ columnIndex }>
              { headers.map((header, headerIndex) => {
                const key = headerIndex;
                if (header.component) return <td key={ key }>{ header.component(Object.assign({}, column), columnIndex) }</td>;
                if (header.field) {
                  const value = header.format ? header.format(column[header.field]) : column[header.field];
                  return (
                    <td key={ key }>{ value }</td>
                  );
                }
                return <td key={ key } />;
              }) }
            </tr>
          );
        }) }
      </tbody>
    </Table>
  );
};

export default EditableTable;
