// more boostrap theme cdn found: https://www.bootstrapcdn.com/bootswatch/
// import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './reducers';
import App from './App';

import * as serviceWorker from './serviceWorker';

const Index: React.FunctionComponent = (): React.ReactElement => (
  <Provider store={ store }>
    <App />
  </Provider>
);
  
ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
