import { SagaIterator } from 'redux-saga';
import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import * as queryString from 'query-string';
import moment from 'moment';
import _ from 'lodash';

import { request } from './index';
import {
  GET_SCHEDULER_TECHNICIANS,
  GET_SCHEDULER_PROJECTS,
  GET_SCHEDULER_UNASSIGNED_PROJECTS,
  GET_DASHBOARD_PROJECTS,
  GET_SCHEDULER_FIELD_VALUES,
  UPDATE_SCHEDULER_PROJECT,
  UPDATE_SCHEDULER_FIELD_VALUES,
  SEND_SCHEDULER_DISPATCH,
  GET_SCHEDULER_VEHICLE,
  GET_PROJECT,
  UPDATE_PROJECT,
} from '../constants/scheduler';
import { setSchedulerTechnicians, setSchedulerProjects, setSchedulerUnassignedProjects, setSchedulerCustomFieldValues, setSchedulerVehicle, setProject } from '../actions/scheduler';

interface SagaActionProps {
  type: string;
  params?: any;
  query?: string;
  payload?: any;
  nextFunc?: any;
}

export function* getSchedulerTechnicians({ params }: SagaActionProps): SagaIterator {
  try {
    const qs = queryString.stringify(params);
    const { data } = yield call(request.get.bind(null, `/scheduler/technicians?${qs}`));
    yield put(setSchedulerTechnicians(data.resources));
  } catch (e) {
    console.error(e);
  }
}

export function* getSchedulerProjects({ params }: SagaActionProps): SagaIterator {
  try {
    const qs = queryString.stringify(params);
    const { data }: any = yield call(request.get.bind(null, `/scheduler/projects?${qs}`));
    const browserTimezone = moment().format("Z")

    data.resources.map((dat: any) => {
      console.log("[info] converted date - ",
        dat.projectRefnumber,
        moment.parseZone(dat.startDate).utcOffset(browserTimezone).format("YYYY-MM-DDTHH:mm:ss"),
        moment.parseZone(dat.endDate).utcOffset(browserTimezone).format("YYYY-MM-DDTHH:mm:ss")
      )

      dat.startDate = moment.parseZone(dat.startDate).utcOffset(browserTimezone).format("YYYY-MM-DDTHH:mm:ss");
      dat.endDate = moment.parseZone(dat.endDate).utcOffset(browserTimezone).format("YYYY-MM-DDTHH:mm:ss");

      if (dat.startDate === dat.endDate) {
        dat.endDate = moment(dat.startDate).add(2, 'day').format('YYYY-MM-DDTHH:mm:ssZ');
      }

      return dat;
    });

    yield put(setSchedulerProjects(data.resources));
  } catch (e) {
    console.error(e);
  }
}

export function* updateSchedulerProjects({ params }: SagaActionProps): SagaIterator {
  try {
    const { t, project, technician, prevTechnician, u } = params;
    const { resourceId, id, projectId, startDate, endDate } = project;

    // NOTE: should get rid of this date formatting if backend realise to implement timezone logic
    const StartDate = moment(startDate).utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    const EndDate = moment(endDate).utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

    // let uniqueUserId = props.location.query.u;
    const data: any = {
      t,
      EmployeeID: technician.employeeId,
      ProjectID: projectId,
      StartDate,
      EndDate,
      EmployeeUniqueId: resourceId,
      projectUniqueId: id,
      timezone: moment().format("Z"),
      u
    };

    if (prevTechnician && prevTechnician.employeeId !== technician.employeeId) {
      data.fromEmployeeID = prevTechnician.employeeId;
    }

    yield call(request.put.bind(null, '/scheduler/projects/put', data));
  } catch (e) {
    console.error(e);
  }
}

export function* getSchedulerUnassignedProjects({ params, query }: SagaActionProps): SagaIterator {
  try {
    if (!params.sortBy) delete params.sortBy;

    const qs = queryString.stringify(params);

    const { data }: any = yield call(request.get.bind(null, `/scheduler/projects/unassigned?${qs}${query || ''}`));

    const values = data.resources.map((dat: any) => {
      let duration = 48; // default 2 days
      if (dat.startDate && dat.endDate) {
        const startDate = moment(dat.startDate);
        const endDate = moment(dat.endDate);
        duration = endDate.diff(startDate, 'hours');
      }
      return {
        ...dat,
        duration,
        durationUnit: 'h',
      }
    });
    yield put(setSchedulerUnassignedProjects(values));
  } catch (e) {
    console.error(e);
  }
}

export function* getSchedulerCustomFieldValues({ params }: SagaActionProps): SagaIterator {
  try {
    const { project, t } = params;
    let valuePromises: any = [];

    _.forEach(project.customFormFields, (fields, index) => {
      const qs = `t=${t}&projectID=${project.projectId}`;

      if (fields.inputType === 'dropdown') {
        valuePromises.push(
          call(request.get.bind(null, `/ux/projects/custom/dropdowntype?${qs}&propertyname=${index}`))
        );
      } else {
        valuePromises.push(
          call(request.get.bind(null, `/ux/projects/custom/Field?${qs}&propertyname=${index}`))
        );
      }
    });

    const responses = yield all(valuePromises);

    const formValues: any = {};

    _.forEach(responses, (response) => {
      const { config: { url }, data: { resources } } = response;
      const { propertyname }: any = queryString.parse(url);
      formValues[propertyname] = resources || '';
    });

    yield put(setSchedulerCustomFieldValues(formValues));
  } catch (e) {
    console.error(e);
  }
}

export function* updateSchedulerCustomFieldValues({ params }: SagaActionProps): SagaIterator {
  try {
    yield call(request.put.bind(null, '/ux/projects/custom/put', params));
  } catch (e) {
    console.error(e);
  }
}

export function* getSchedulerVehicle({ params }: SagaActionProps): SagaIterator {
  try {
    const qs = queryString.stringify(params);
    const { data }: any = yield call(request.get.bind(null, `https://api.snapsuite.io/api/UX/Vehicle?${qs}`));
    yield put(setSchedulerVehicle(data));
  } catch (e) {
    console.error(e);
  }
}

export function* sendSchedulerDispatch({ params, nextFunc }: SagaActionProps): SagaIterator {
  try {
    const { vehicleID, projectID, message, employeeID, t } = params;
    const payload = {
      t,
      ProjectID: projectID,
      SMSMessage: message,
      UserID: employeeID,
      messageType: false,
    };
    const qs = queryString.stringify(payload);
    const ids = vehicleID.map(({ value }: any) => value).join(',');
    const { data } = yield call(request.get.bind(null, `https://api.snapsuite.io/api/SMS/SendSMS?MultipleVehicleID=${ids}&${qs}`));
    if (typeof nextFunc === 'function') yield call(nextFunc({
      success: data.isSuccessful,
      message: data.message,
    }));
  } catch (e) {
    console.error(e);
  }
}

export function* getProject({ params }: SagaActionProps): SagaIterator {
  try {
    const qs = queryString.stringify(params);
    const { data } = yield call(request.get.bind(null, `https://api.snapsuite.io/api/Projects/Details?${qs}`));
    yield put(setProject(data));
  } catch (e) {
    console.error(e);
  }
}

export function* updateProject({ payload, nextFunc }: SagaActionProps): SagaIterator {
  try {
    const qs = queryString.stringify(payload);
    const { data } = yield call(request.get.bind(null, `https://api.snapsuite.io/api/Projects/Update?${qs}`));
    if (typeof nextFunc === 'function') yield call(nextFunc({
      success: data.isSuccessful,
      message: data.message,
    }));
  } catch (e) {
    console.error(e);
  }
}

export function* getDashBoardProjects({ params, query }: SagaActionProps): SagaIterator {
  try {
    const qs = params.t

    const { data }: any = yield call(request.get.bind(null, `https://saturn.snapsuite.io/api/search/scheduler${qs}${query || ''}`));

    const values = data.resources.map((dat: any) => {
      let duration = 48; // default 2 days
      if (dat.projectStartDate && dat.projectEndDate) {
        const startDate = moment(dat.projectStartDate);
        const endDate = moment(dat.projectEndDate);
        duration = endDate.diff(startDate, 'hours');
      }
      return {
        ...dat,
        duration,
        durationUnit: 'h',
      }
    });
    // debugger
    yield put(setSchedulerUnassignedProjects(values));
  } catch (e) {
    console.error(e);
  }
}

export default function* schedulerSaga(): SagaIterator {
  yield takeLatest(GET_SCHEDULER_TECHNICIANS, getSchedulerTechnicians);
  yield takeLatest(GET_SCHEDULER_PROJECTS, getSchedulerProjects);
  yield takeLatest(GET_SCHEDULER_UNASSIGNED_PROJECTS, getSchedulerUnassignedProjects);
  yield takeLatest(GET_DASHBOARD_PROJECTS, getDashBoardProjects)
  yield takeLatest(GET_SCHEDULER_FIELD_VALUES, getSchedulerCustomFieldValues);
  yield takeLatest(UPDATE_SCHEDULER_PROJECT, updateSchedulerProjects);
  yield takeLatest(UPDATE_SCHEDULER_FIELD_VALUES, updateSchedulerCustomFieldValues);
  yield takeLatest(SEND_SCHEDULER_DISPATCH, sendSchedulerDispatch);
  yield takeLatest(GET_SCHEDULER_VEHICLE, getSchedulerVehicle);
  yield takeLatest(GET_PROJECT, getProject);
  yield takeLatest(UPDATE_PROJECT, updateProject);
}
