import axios from 'axios';
import { all } from 'redux-saga/effects';

import techniciansSaga from './technicians';
import schedulerSaga from './scheduler';

const { REACT_APP_API_URL,REACT_APP_API_KEY } = process.env;

export const request = axios.create({
  baseURL: REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    "x-functions-key":REACT_APP_API_KEY
  },
});

// eslint-disable-next-line
export default function* rootSaga (): any {
  yield all([
    techniciansSaga(),
    schedulerSaga(),
  ]);
}
