import { AnyAction } from 'redux';
import { SchedulerTechnician, SchedulerProject } from '../entities/scheduler';
import { SET_SCHEDULER_TECHNICIANS, SET_SCHEDULER_PROJECTS, SET_SCHEDULER_UNASSIGNED_PROJECTS, ADD_SCHEDULER_PROJECT, SET_SCHEDULER_FIELD_VALUES, SET_SCHEDULER_VEHICLE, SET_PROJECT } from '../constants/scheduler';

export interface SchedulerState {
  techinicians: SchedulerTechnician[];
  projects: SchedulerProject[];
  project: any;
  unassigned: SchedulerProject[];
  vehicle: any;
  customFields: any;
  eventsVersion: number;
  resourcesVersion: number;
}

const initialState: SchedulerState = {
  techinicians: [],
  projects: [],
  project: {},
  unassigned: [],
  vehicle: [],
  customFields: {},
  eventsVersion: 0,
  resourcesVersion: 0,
};

export default function reducer (state: SchedulerState = initialState, action: AnyAction): SchedulerState {
  switch (action.type) {
    case SET_SCHEDULER_TECHNICIANS:
      return { ...state, techinicians: action.payload, resourcesVersion: state.resourcesVersion + 1 };

    case SET_SCHEDULER_PROJECTS:
      return { ...state, projects: action.payload, eventsVersion: state.eventsVersion + 1 };

    case SET_SCHEDULER_UNASSIGNED_PROJECTS:
      return { ...state, unassigned: action.payload };

    case SET_SCHEDULER_FIELD_VALUES:
      return { ...state, customFields: action.payload };

    case ADD_SCHEDULER_PROJECT:
      return { ...state, projects: [ ...state.projects, action.payload ] };

    case SET_SCHEDULER_VEHICLE:
      return { ...state, vehicle: action.payload };

    case SET_PROJECT:
      return { ...state, project: action.payload };

    default:
      return state;
  }
}
