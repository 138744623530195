import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import technicians, { TechniciansState } from './technicians';
import scheduler, { SchedulerState } from './scheduler';
import rootSaga from '../sagas';

export interface AppState {
  technicians: TechniciansState;
  scheduler: SchedulerState;
}

export interface DispatchActions {
  [key: string]: Function;
}

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  technicians,
  scheduler,
});
const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(rootSaga);

export default store;
