import { SagaIterator } from 'redux-saga';
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import * as queryString from 'query-string';

import { request } from './index';
import { GET_TECHNICIANS, GET_TECHNICIANS_LOGS, UPDATE_TECHNICIANS_LOG, DELETE_TECHNICIANS_LOGS } from '../constants/technicians';
import { setTechnicians, setTechniciansLogs } from '../actions/technicians';

interface SagaActionProps {
  type: string;
  params?: any;
  payload?: any;
}

export function* getTechnicians ({ params }: SagaActionProps): SagaIterator {
  try {
    const qs = queryString.stringify(params);
    const { data }: any = yield call(request.get.bind(null, `/ux/technicians?${ qs }`));
    yield put(setTechnicians(data.resources));
  } catch (e) {
    console.error(e);
  }
}

export function* getTechniciansLogs ({ params }: SagaActionProps): SagaIterator {
  try {
    const qs = queryString.stringify(params);
    const { data }: any = yield call(request.get.bind(null, `/technicians/log?${ qs }`));
    yield put(setTechniciansLogs(data.resources));
  } catch (e) {
    console.error(e);
  }
}

export function* updateTechniciansLogs ({ payload, params }: SagaActionProps): SagaIterator {
  try {
    const { nextFunc } = params;
    yield call(request.put.bind(null, `/technicians/put`, payload));
    if (typeof nextFunc === 'function') yield call(nextFunc);
  } catch (e) {
    console.error(e);
  }
}

export function* deleteTechniciansLog ({ params: {  nextFunc, ...params } }: SagaActionProps): SagaIterator {
  try {
    const qs = queryString.stringify(params);
    yield call(request.delete.bind(null, `/technicians/log/delete?${ qs }`));
    if (typeof nextFunc === 'function') yield call(nextFunc);
  } catch (e) {
    console.error(e);
  }
}

export default function* techniciansSaga (): SagaIterator {
  yield takeLatest(GET_TECHNICIANS, getTechnicians);
  yield takeLatest(DELETE_TECHNICIANS_LOGS, deleteTechniciansLog);
  yield takeLatest(GET_TECHNICIANS_LOGS, getTechniciansLogs);
  yield takeLatest(UPDATE_TECHNICIANS_LOG, updateTechniciansLogs);
}
