import * as React from 'react';
import {
  BrowserRouter as Router, Switch, Route
} from 'react-router-dom';

import GlobalStyle from './globalStyles';
import SchedulerView from './views/Scheduler';
// import TimeTrackingTable from './views/TimeTracking/Table';

class AppComponent extends React.Component {
  render (): React.ReactElement {
    return (
      <Switch>
        <Route path='*' component={ SchedulerView } />
        {/* <Route path='/scheduler' component={ SchedulerView } /> */}
        {/* <Route path='/timetracking/table' component={ TimeTrackingTable } /> */}
      </Switch>
    )
  }
}

const Root = (): React.ReactElement => (
  <Router>
    <GlobalStyle />
    <AppComponent />
  </Router>
);

export default Root;
