import { AnyAction } from 'redux';
import {
  GET_TECHNICIANS, GET_TECHNICIANS_LOGS, UPDATE_TECHNICIANS_LOG, DELETE_TECHNICIANS_LOGS, SET_TECHNICIANS, SET_TECHNICIANS_LOGS,
} from '../constants/technicians';
import { Technician, TechnicianLog } from '../entities/technicians';

export function getTechnicians (t: string): AnyAction {
  return {
    type: GET_TECHNICIANS,
    params: {
      t,
    },
  };
}

export function getTechniciansLogs (t: string, employeeID: number, projectID: number): AnyAction {
  return {
    type: GET_TECHNICIANS_LOGS,
    params: {
      t,
      employeeID,
      projectID
    },
  };
}

export function deleteTechniciansLog (t: string, taskID: number, projectID: number, nextFunc?: Function): AnyAction {
  return {
    type: DELETE_TECHNICIANS_LOGS,
    params: {
      t,
      taskID,
      projectID,
      nextFunc
    },
  };
}

export function setTechnicians (payload: Technician[]): AnyAction {
  return {
    type: SET_TECHNICIANS,
    payload,
  };
}

export function setTechniciansLogs (payload: TechnicianLog[]): AnyAction {
  return {
    type: SET_TECHNICIANS_LOGS,
    payload,
  };
}

export function updateTechniciansLog (payload: TechnicianLog[], nextFunc?: Function): AnyAction {
  return {
    type: UPDATE_TECHNICIANS_LOG,
    payload,
    params: {
      nextFunc,
    }
  };
}
